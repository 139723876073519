import { createContext, useState } from "react";
const ChatContext = createContext();

const Context = ({ children }) => {
  const [directLine, setDirectLine] = useState();
  const [openChat, setOpenChat] = useState(false);
  const [Active, setActive] = useState(true);
  const [loginActive, setLoginActive] = useState(false);
  const [show, setShow] = useState(false);
  const [typingIndicator, setTypingIndicator] = useState(false);
  return (
    <ChatContext.Provider
      value={{
        directLine,
        setDirectLine,
        openChat,
        setOpenChat,
        typingIndicator,
        setTypingIndicator,
        Active,
        setActive,
        loginActive,
        setLoginActive,
        show,
        setShow
      }}
    >
      {children}          
    </ChatContext.Provider>
  );
};

export { Context };
export default ChatContext;
