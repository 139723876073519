import React, { useContext, useEffect, useMemo } from "react";
import "./botmsg.css";
import botimg from "../../../../assets/chatbot/botimg.svg";

//import cards ...
import PersonaCard from "./personacard/PersonaCard";
import DetailCard from "./detailcard/DetailCard";
import Apiresponse from "./apiresponse/Apiresponse";
import ChatContext from "../../../../context/Context";
import WelcomeCard from "./welcomeCard/WelcomeCard";
import VehicalDetailCard from "./VehicalDetailsCard/VehicalDetailCard";

const BotMsg = ({ msg }) => {
  //date memomised using use memo hook for the time stampa
  const DateMemoMized = useMemo(
    () =>
      new Date().toLocaleString("en-IN", {
        hour: "numeric",
        minute: "numeric",
      }),
    []
  );

  const { setTypingIndicator } = useContext(ChatContext);
  useEffect(() => setTypingIndicator(false), [setTypingIndicator]);

  const rendderChat = (msg) => {
    console.log(msg.text, "bot side");

    if (msg.inputHint && msg.inputHint === "welocme_card") {
      return <WelcomeCard msg={msg.text} />;
    } else if (msg.inputHint && msg.inputHint === "greetings") {
      return msg.text;
    } else if (msg.inputHint && msg.inputHint === "vechicle-data") {
      return <VehicalDetailCard msg={msg.text} />;
    } 
    else if (msg.inputHint && msg.inputHint === "input-text") {
      const content = JSON.parse(msg.text);
      const contentString = content[0].Content;
      const lines = contentString.split("\n");

      const styledContent = lines.map((line, index) => {
        if (/^\d+\. /.test(line)) {
          // Extract the number and the rest of the line
          const [number, restOfLine] = line.split(". ");
          return (
            <React.Fragment key={index}>
              <span className="styled-number">{number}.</span> {restOfLine}
              <br />
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        );
      });

      return (
        <div className="bot-msg-wrap">
          <pre className="precontent">{styledContent}</pre>
          {(content[0]["File Name"].length > 0 ||
            content[0]["Page No."].length > 0) && (
            <div>
              <hr style={{ margin: "5px 0px" }} />
            </div>
          )}
          <div className="bot-msg-file-page">
            <div className="bot-msg-page">
              {content[0]["Page No."].length > 0 &&
                content[0]["Page No."].map((ans) => <></>)}
            </div>
            <div className="bot-msg-file">
              {content[0]["File Name"].length > 0 &&
                content[0]["File Name"].map((ans, key) => (
                  <>
                    <button className="bot-add-filebtn">
                      <div
                        style={{
                          margin: "3px 0px",
                          fontWeight: "normal",
                          fontSize: "12px",
                        }}
                      >
                        {key + 1 + "." + ans}
                      </div>
                      {content[0]["Page No."].length > 0 &&
                        content[0]["Page No."].map((ans) => (
                          <div className="bot-page-btn">
                            <p style={{ fontSize: "8px", color: "#00069E" }}>
                              Page No:
                            </p>
                            <button className="bot-add-pagebtninside">
                              {ans}
                            </button>
                          </div>
                        ))}
                    </button>
                  </>
                ))}
            </div>
          </div>
        </div>
      );
    }

    return msg.text;
  };

  return (
    <>
      <div className="Bot-msg_Cont">
        <div>
          <img style={{ width: "20px", paddingTop: "5px" }} src={botimg} />
        </div>
        <div>
          <div className="Bot-msg">{rendderChat(msg)}</div>
          <p className="timestampbot">{DateMemoMized}</p>
        </div>
      </div>
    </>
  );
};

export default BotMsg;

// {/* <pre className="preword">{item.Content}</pre> */}
