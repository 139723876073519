import React, { useContext } from "react";
import "./Header.css";
import Assets from "../../assets/Assets";
import tatalogo from "../../assets/chatbot/tatalogo.svg";
import ChatContext from "../../context/Context";
import reload from "../../assets/chatbot/reload.svg";

const Header = ({handleClear}) => {
  const { setOpenChat ,show } = useContext(ChatContext);
  return (
    <div className="chatbot_login_main">
      <div className="header_main_left">
        <div className="Logo_Container">
          <img className="logo" src={tatalogo} alt="Logo" />
        </div>
        <div className="Title_Cont">
          <p>Tata Motors</p>
        </div>
      </div>
      <div className="header_main_right">
        {!show && (<button className="btn" onClick={handleClear}> 
          <img style={{ width: "23px", paddingTop: "2px" }} src={reload} />
        </button>)}
        <button className="basicbutton">
          <img
            src={Assets.Cancel}
            alt="cancel"
            onClick={() => setOpenChat(false)}
          />
        </button>
      </div>
    </div>
  );
};

export default Header;
