import React from 'react';
import './welcomeCard.css';
import tataMotorsLogo from '../../../../../assets/chatbot/tatamotors.png'; // Import the Tata Motors logo image
import welcome from '../../../../../assets/chatbot/welcome.svg'

function WelcomeCard({ msg }) {
  return (
    
    <div>
       <img className='welcome' src={welcome} alt="Tata Motors Logo"  />
    </div>
  );
}

export default WelcomeCard;
