import React, { useCallback, useState } from "react";
import { hooks } from "botframework-webchat";
import "../botmsg.css";

const { usePostActivity } = hooks;

function PersonaCard({ heading, buttonsmap }) {
  const sendEvent = usePostActivity();

  const handleSendButtonValue = useCallback((ans) => {
    sendEvent({
      type: "message",
      name: "buttons",
      text: ans,
      value: false,
    });
    setDisabledButton(true);
  });

  const [disabledButton, setDisabledButton] = useState(false);

  return (
    <div className="btn_Container">
      <div>{heading}</div>
      {buttonsmap.map((e) => (
        <button
          className="buttonsCard"
          onClick={() => handleSendButtonValue(e)}
          disabled={disabledButton}
        >
          {e}
        </button>
      ))}
    </div>
  );
}

export default PersonaCard;
