import { useState } from "react";
import { hooks } from "botframework-webchat";
import "./Login.css";
// import bottomSheetIcon from "../../../../assets/img/chatbot/bottom-sheet.svg";

const { usePostActivity } = hooks;

function LoginCard({ setLoginActive }) {
  // state for the login type and value
  const [loginType, setLoginType] = useState("sales_agent");
  const [fristName, setFristName] = useState("");
  const [password, setPassword] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  // define for the send event
  const sendEvent = usePostActivity();

  //login section method for where id and password send to backend
  const btnCallingEventsend = (loginType, fristName, password) => {
    setLoginActive(false);
    sendEvent({
      type: "message",
      name: "loginDetails",
      value: {
        userType: loginType,
        name: fristName,
        pass: password,
      },
    });
  };
  
  return (
    <>
      <div className="bg-white">
        <div className="login_detail_main">
          <div className="sheetIcon">
            {/* <img src={bottomSheetIcon} alt="bootomsheet" /> */}
          </div>
          <h4 className="continue-login-headtext">Please login to continue</h4>
          <div className="login_options pt-2">
            <button
              className={`items text-center mb-1 cursor-pointer ${
                loginType === "sales_agent" ? "active" : ""
              }`}
              
              onClick={() => setLoginType("sales_agent")}
            >
              Sales Agent 
            </button>
            <button
              className={`items text-center mb-1 cursor-pointer ${
                loginType === "Customer" ? "active" : ""
              }`}
              onClick={() => setLoginType("Customer")}
            >
              Customer
            </button>
          </div>
          <div className="form-detail pt-2">
            <div className="user-input-wrp">
              <input
                type="textbox"
                onChange={(e) => setFristName(e.target.value)}
                className="inputText"
                placeholder="User name"
                required
              />
              <span className="floating-label">User name</span>
            </div>
            <div className="user-input-wrp">
              <input
                type="password" // change type to "password"
                onChange={(e) => setPassword(e.target.value)}
                className="inputText"
                placeholder="Password"
                onKeyPress={(e) =>
                  e.charCode === 13
                    ? btnCallingEventsend(loginType, fristName, password)
                    : ""
                }
                required
              />
              <span className="floating-label">Password</span>
            </div>
            <div className="login_button d-flex py-3 px-0 gap-2 border-0">
              <button
                disabled={btnDisabled}
                className="Cancel_btton"
                onClick={() => {
                  btnCallingEventsend("cancel", "no", "no");
                  setBtnDisabled(true);
                }}
              >
                Cancel
              </button>
              <button
                disabled={btnDisabled}
                className="Login_btton inner-login-btn text-white  login-btn"
            
                onClick={() => {
                  btnCallingEventsend(loginType, fristName, password);
                  setBtnDisabled(true);
                }}
              >
                Login 
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginCard;
