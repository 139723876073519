import React, { useEffect, useContext } from "react";
import { hooks } from "botframework-webchat";

// component and styling imports
import Modal from "react-bootstrap/Modal";
import "../BotJourneyEndCard/BotJourneyEnd.css";

//import context
import ChatContext from "../../../../../context/Context";

// destructure hooks of webchat
const { useActivities, useConnectivityStatus, usePostActivity } = hooks;

export default function BotJourneyENDCard() {
  const { show, setShow } = useContext(ChatContext);
  const [activities] = useActivities();

  const sendEvent = usePostActivity();

  const btnCallingEventsend = () => {
    sendEvent({
      type: "message",
      channelData: {
        value: {
          type: "restart",
        },
      },
    });
    setShow(false);
  };

  useEffect(() => {
    activities.length = 0;
  }, [activities, show]);

  return (
    <div className="outer-end-modal">
      {show && (
        <div
          className="end-modal-card-main "
          style={{ display: "block", position: "initial" }}
        >
          <Modal.Dialog>
            <Modal.Body className="end-modal-card-main-body">
              <div className="display-flex-property">
                <p className="end-first-text">
                  Due to inactivity, the conversation has been ended. <br />
                  If you have any further questions, <br />
                  please feel free to start a new conversation.{" "}
                </p>
                <a
                  href={() => false}
                  className="end-second-text"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    onClick={() => {
                      btnCallingEventsend();
                    }}
                    className="end-second-text-inside"
                  >
                    Restart conversation
                  </div>
                </a>
              </div>
            </Modal.Body>
          </Modal.Dialog>
        </div>
      )}
    </div>
  );
}
