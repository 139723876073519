import { useContext, useEffect, useState } from "react";
import thumbUpIMG from "../../assets/thumbup1.png";
import thumbdownIMG from "../../assets/thumbdown2.png";
import "./thumbcard.css";
import thumbsup_passive from "../../assets/thumbs-up-passive.svg";
import thumbsdown_passive from "../../assets/thumbs-down-passive.svg";
import { hooks } from "botframework-webchat";
import ChatContext from "../../context/Context";

const { useActivities, usePostActivity } = hooks;

const Thumbcard = () => {
  const { botMsg, setBotMsg, useMessge, setUserMsg } = useContext(ChatContext);

  const [activities] = useActivities();
  const sendEvent = usePostActivity();

  

  const btnCallingEventsend = (name, useMessge, botMsg) => { 
    sendEvent({
      type: "event",
      name: "thumbscard",
      value : {
        type: name,
     
      },
    });
  };

  const [thumsUp, setThumbsup] = useState(false);
  const [thumsDown, setThumbsDown] = useState(false);
  const [disabled1, setDisabled] = useState(false);

  return (
    <div style={{ "display":"flex", gap: "5px", "justifyContent": "flex-start", "alignItems":"flex-end", "paddingBottom": "15px"}} className="d-flex">
      
      <button disabled={disabled1} className="defautStayle">
        <img
          style={{ cursor: "pointer" }}
          src={thumsDown ? thumbdownIMG : thumbsdown_passive}
          onClick={() => {
            btnCallingEventsend("dislike", useMessge, botMsg);
            setDisabled(true);
            setThumbsDown((e) => !e);
            setThumbsup(thumsDown);

            // setThumbsup((e) => !e);
          }}
          alt="late"
          // onMouseEnter={() => setThumbsDown((e) => !e)}
          // onMouseLeave={() => setThumbsDown((e) => !e)}
        />
      </button>
      <button className="mx-2 defautStayle" disabled={disabled1}>
        <img
          style={{ cursor: "pointer", paddingBottom: "2px"}}
          src={thumsUp ? thumbUpIMG : thumbsup_passive}
          alt="thumb"
          onClick={() => {
            btnCallingEventsend("like", useMessge, botMsg);
            setThumbsup((e) => !e);
            setThumbsDown(thumsUp);
            setDisabled(true);
          }}
          // onMouseEnter={() => setThumbsup((e) => !e)}
          // onMouseLeave={() => setThumbsup((e) => !e)}
        />
      </button>
    </div>
  );
};

export default Thumbcard;
