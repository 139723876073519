import React, { useContext, useEffect, useMemo } from "react";
import "./usermsg.css";
import ChatContext from "../../../../context/Context";
import avatar from "../../../../assets/chatbot/avatar.svg";

const UserMsg = ({ msg }) => {
  //date memomised using use memo hook for the time stamp
  const DateMemoMized = useMemo(
    () =>
      new Date().toLocaleString("en-IN", {
        hour: "numeric",
        minute: "numeric",
      }),
    []
  );

  console.log(msg, "usermsg");
  const { setTypingIndicator } = useContext(ChatContext);
  useEffect(() => setTypingIndicator(true), [setTypingIndicator]);
  if (msg.text) {
    return (
      <>
        <div className="User-msg_Cont">
          <div className="user-msg-time">
            <div className="User-msg">{msg.text !== "" && msg.text}</div>
            <div className="timestamp">
              <p>{DateMemoMized}</p>
            </div>
          </div>
          <img style={{ width: "23px", paddingTop: "5px" }} src={avatar} />
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default UserMsg;

//  if (msg.text) {
//    return (
//      <>
//        <div className="User-msg_Cont">
//          <div className="User-msg">{msg.text !== "" && msg.text}</div>
//        </div>
//        <div className="timestamp">
//          <p>{DateMemoMized}</p>
//        </div>
//      </>
//    );
//  } else {
//    return null; // Return null to skip rendering the message
//  }
