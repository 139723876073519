import { useContext, useEffect, useState } from "react";

//import context
import ChatContext from "../context/Context";
import config from "../config/config.json";

//import directline from the webchat
import { createDirectLine } from "botframework-webchat";
import { Components } from "botframework-webchat";
import Chatsection from "../chatbotcomponents/chatsection/Chatsection";

//destructure composer component and directline token fetching
const { Composer } = Components;

async function getDirectLineToken() {
  let token = config.bottoken;
  return token;
}

const ChatBot = () => {
  // const {  } = useContext(ChatContext);
const [directLine, setDirectLine] = useState();
  useEffect(() => {
    if (!directLine) {
      getDirectLineToken().then((token) =>
        setDirectLine(createDirectLine({ token }))
      );
    }
  }, [directLine, setDirectLine]);

  return (
    <div>
      {!!directLine && (
        <Composer directLine={directLine}>
          <Chatsection />
        </Composer>
      )}
    </div>
  );
};

export default ChatBot;
