import React, { useContext, useEffect, useState, useRef } from "react";
import { hooks } from "botframework-webchat";
import Header from "../Header/Header.js";
import "./chatsection.css";
import MessageSection from "./messagesection/MessageSection";
import InputBox from "../inputtextbox/InputBox.js";
import ChatContext from "../../context/Context.js";
import LoginFirstCard from "./messagesection/LoginComponent/LoginFirstCard.js";
import LoginCard from "./messagesection/LoginComponent/loginCard.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { useActivities, usePostActivity } = hooks;
const Chatsection = () => {
  const {
    Active,
    setActive,
    loginActive,
    setLoginActive,
    openChat,
    show,
    setShow,
  } = useContext(ChatContext);
  const [activities] = useActivities();
  const [clear, setClear] = useState(true);

  const timeoutIdRef = useRef(null);

  console.log(show, "show");

  const triggerAfterDelay = () => {
    console.log("Function triggered after 5 minutes");
    // btnCallingEventsend();
    // Perform activities or logic here

    setShow(true);
  };

  useEffect(() => {
    if (
      activities.length > 0 &&
      activities[activities.length - 1].type !== "event"
    ) {
      clearTimeout(timeoutIdRef.current);
      let timeDelay = 1000 * 5 * 60; // 5min delay timout
      // let timeDelay = 5000; // 5second delay timout


      timeoutIdRef.current = setTimeout(triggerAfterDelay, timeDelay);
      return () => {
        clearTimeout(timeoutIdRef.current);
      };
    }
  }, [activities]);

  console.log(activities, "activities"); // console for the activities...
  const sendEvent = usePostActivity();
  console.log(openChat, "open");
  // useEffect(() => {
  //   !!activities &&
  //     activities.map((e) =>
  //       e.type === "event" && e.from.role === "bot"
  //         ? setLoginActive(true)
  //         : setLoginActive(false)
  //     );
  // }, [activities]);

  const btnCallingEventsend = () => {
    sendEvent({
      type: "message",
      channelData: {
        value: {
          type: "clearchat",
        },
      },
    });
  };

  const handleClear = () => {
    activities.length = 0;
    setClear(false);
    btnCallingEventsend();
    activities && console.log(activities, "activities clear");
  };

  useEffect(() => {}, [clear]);

  useEffect(() => {
    if (
      activities.length > 0 &&
      activities[activities.length - 1].from.role === "bot" &&
      activities[activities.length - 1].text === ""
    ) {
      toast.error("write your query again");
    }
  }, [activities]);

  return (
    <div className="headerrr" style={{ display: !openChat ? "none" : "" }}>
      <Header handleClear={handleClear} />
      {/* <div>
          {!Active && (
            <LoginFirstCard
              setActive={setActive}
              setLoginActive={setLoginActive}
            />
          )}
        </div> */}
      {/* <div>{loginActive && <LoginCard setLoginActive={setLoginActive} />}</div> */}
      <ToastContainer />

      <MessageSection />
      {!show && <InputBox />}
    </div>
  );
};

export default Chatsection;
