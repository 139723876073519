import React, { useContext } from "react";

import ChatBot from "./pages/ChatBot";

//asstes .....
import tatabot from "../src/assets/chatbot/tatabot.png";
import Assets from "./assets/Assets";
//import css
import "./App.css";

//import contecxt
import ChatContext from "./context/Context";
import { RouterProvider } from "react-router-dom";

const App = () => {
  const { openChat, setOpenChat } = useContext(ChatContext);

  return (
    <div>
      {openChat && <ChatBot />}
      {!openChat && (
        <div className="chat-bot">
          <img
            onClick={() => setOpenChat((e) => !e)}
            src={tatabot}
            alt="chatbot"
            style={{ width: "60px", cursor: "pointer" }}
          />
        </div>
      )}
    </div>
  );
};

export default App;
