import React from "react";
import { hooks } from "botframework-webchat";
import Assets from "../../../../assets/Assets";
import "./Login.css";

const { usePostActivity } = hooks;
function LoginFirstCard({ setActive, setLoginActive }) {
  const sendEvent = usePostActivity();
  //login section method for where id and password send to backend
  const btnCallingEventsend = () => {
    setActive(false);
    setLoginActive(true);
    sendEvent({
      type: "event",
      name: "login",
      value: "login",
    });
  };

  return (
    <div className="chatbot_login text-white">
      <div className="login_main_chatwindow pt-lg-3 pt-2 px-2">
        <img
          src={Assets.Login}
          alt=""
          className="chatbot_login_img py-lg-3 py-2"
        />
        <div className="Login_Text">
          <h2 className="chatbot_login_heading text-center">Welcome</h2>
          <p className="chatbot_login_para text-center py-2">
            Whether you're a customer seeking answers to your queries or even a
            sales person looking for product recommendations for your customers,
            our chatbot has got you covered.
          </p>
        </div>
        <button className="chatbot_login_button" onClick={btnCallingEventsend}>
          Login
        </button>
      </div>
    </div>
  );
}

export default LoginFirstCard;
