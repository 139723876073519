import { useRef, useEffect, useContext, useState } from "react";
import BotMsg from "./botmsg/BotMsg";
import UserMsg from "./usermsg/UserMsg";
import { hooks } from "botframework-webchat";
import "./messagesection.css";
import ChatContext from "../../../context/Context";
import TypingIndicator from "../../typingIndicator/TypingIndicator";
import Thumbcard from '../../Thumbcard/Thumbcard'
import BotJourneyENDCard from '../../../chatbotcomponents/chatsection/messagesection/botmsg/BotJourneyEndCard/BotJourneyENdCard'



const { useActivities } = hooks;
const MessageSection = () => {
  const [activities] = useActivities();
  const { show, setShow } =
    useContext(ChatContext);

console.log(activities, "newactivities")

 
  const { typingIndicator } = useContext(ChatContext);

  // always scroll bottom functionality function ...
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };


  
  return (
    <div className="msg_Cont" >
      <div className="d-flex flex-column gap-1 py-1">
        {!!activities && (   
          <>
            {/*rendering user chat and bot chat maping of the activities...*/}
            { activities.map((msg, i) =>
              msg.type === "message" && msg.from.role === "user" ? (
                <UserMsg msg={msg} key={i} />
              ) : (
                msg.type === "message" &&
                msg.from.role === "bot" && 
 
                <div className="thumb-container-botmsg">
                <BotMsg msg={msg} key={i} />
                {msg.inputHint!="welocme_card" && <Thumbcard/>}
                </div>

              )
            )}
            {/* scroll bottom component...*/}
            <AlwaysScrollToBottom />
            {/* typing indicator component when user want to be send an message...*/}
            {typingIndicator && <TypingIndicator />}
          </>
        )}
        {
          show && (<BotJourneyENDCard  />)
        }
       
      </div>
    </div>
  );
};

export default MessageSection;
