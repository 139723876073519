import { useState, useCallback, useContext } from "react";
import { hooks } from "botframework-webchat";
import "./inputbox.css";

// import assets.. from the code.
import Assets from "../../assets/Assets";
import inputarrow from '../../assets/chatbot/inputarrow.png'
import ChatContext from "../../context/Context";

//destructure hooks...
const { useSendMessage } = hooks;
const InputBox = () => {
  const sendMessage = useSendMessage();
  const [inputValue, setInputValue] = useState("");

  const handleClickSend = useCallback(
    (e) => {
      sendMessage(e);
      setInputValue("");
    },
    [sendMessage]
  );

const {show} = useContext(ChatContext)

  return (
    <div className="input">
      
      <input
        type="textbox"
        placeholder="Type Here ..."
        width={100}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) =>
          e.charCode === 13 ? handleClickSend(inputValue) : ""
        }


      />
      <img
        src={inputarrow}
        onClick={() => handleClickSend(inputValue)}
        alt="sendimage"
      />
    </div>
  );
};

export default InputBox;
