const Assets = {
  Sendicon: require("./chatbot/Vector (7).svg").default,
  Refresh: require("./chatbot/Vector (4).svg").default,
  Logo: require("./chatbot/ct_logo2 (1) 1.svg").default,
  Cancel: require("./chatbot/Vector (5).svg").default,
  Home: require("./landingpage/Group 237570.svg").default,
  Login: require("./chatbot/Group 397.svg").default,
  botLogo: require("./landingpage/chatboticon.svg").default,
  Main: require("./landingpage/Main.svg").default,
  HomeLogo: require("./landingpage/ct 22 1.svg").default,
};

export default Assets;
