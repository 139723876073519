import React from "react";
import './VehicalDetailCard.css'

function VehicalDetailCard({ msg }) {
  const cardData = JSON.parse(msg);

  console.log(cardData.vechiles, "card data vehicle");
  return (
    <div className="Vehicalcard-top">
      {cardData.vechiles.map((item) => (
        <>
          <div><span className="vehical-text"> Full Name - </span>  {item.full_name}</div>
          <div> <span className="vehical-text"> Manufacturing_year - </span> {item.manufacturing_year}</div>
          <div> <span className="vehical-text"> Model - </span> {item.model}</div>
          <div> <span className="vehical-text"> Registration_number - </span>{item.registration_number}</div>
          <div> <span className="vehical-text"> Segment - </span> {item.segmant}</div>
          <div> <span className="vehical-text"> Serial_number - </span> {item.serial_number}</div>
          <div>  <span className="vehical-text"> Varient - </span>{item.varient}</div>

        </>
      ))}
    </div>
  );
}

export default VehicalDetailCard;
