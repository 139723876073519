import React, { useState } from "react";
import InputCards from "../inputcards/InputCards";

import "./detailscard.css";

import { hooks } from "botframework-webchat";

const { usePostActivity } = hooks;

const DetailCard = ({ msg }) => {
  const sendEvent = usePostActivity();

  const [inputVlaue, setInputValue] = useState({});
  const [disabled, setdisabled] = useState(false);

  console.log(inputVlaue, "inputVlaue");

  const handleClicksend = (inputVlaue, type, name) => {
    sendEvent({
      type: "message",
      text: type,
      name: name,
      value: inputVlaue,
    });
  };

  return (
    <div className="mhdksjhd">
      <div className="DetailCard_Heading">{msg.heading}</div>
      {Object.keys(msg.inputValuePairs).map((e, i) => {
        return (
          <InputCards
            value={e}
            inputVlaue={inputVlaue}
            setInputValue={setInputValue}
          />
        );
      })}
      <div className="Btn_detailCard">
        <button
          className="CancelButton_DetailCard"
          disabled={disabled}
          onClick={() => {
            handleClicksend("", "cancel", "cardsDetails");
            setdisabled(true);
          }}
          handleClicksend
        >
          Cancel 
        </button>
        <button
          className="submitbutton"
          disabled={disabled}
          onClick={() => {
            handleClicksend(inputVlaue, "submit", "cardsDetails");
            setdisabled(true);
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default DetailCard;
